<template>
  <div id="footer" class="h-fill text-center mt-10 md:px-40 md:mb-20 pb-10 md:pb-20 animate-footer">
      <div class="text-4xl md:float-right">
        <a  href="https://github.com/kaushalpurohit" target="_blank"
            rel="noreferrer">
          <i class="ri-github-fill px-4 hover:text-white duration-300"></i>
        </a>
        <a  href="https://linkedin.com/in/kaushalpurohit" target="_blank"
            rel="noreferrer">
          <i class="ri-linkedin-fill px-4 hover:text-white duration-300"></i>
        </a>
        <a  href="https://reddit.com/user/kaushalpurohit" target="_blank"
            rel="noreferrer">
          <i class="ri-reddit-fill px-4 hover:text-white duration-300"></i>
        </a>
        <a  href="https://mobile.twitter.com/KaushalRPurohit" target="_blank"
            rel="noreferrer">
          <i class="ri-twitter-fill px-4 hover:text-white duration-300"></i>
        </a>
      </div>
      <div class="md:float-left md:mt-0 mt-4 text-center md:text-left">
        <p class="invisible h-0 md:h-full md:visible">
          <a  href="https://kaushalpurohit.dev" class="logo text-6xl md:text-5xl hover:text-white duration-300"
              rel="noopenor">
            KP
          </a>
        </p>
        <p class="md:mt-2">
          <a  href="https://kaushalpurohit.dev" class="text-xl md:text-2xl hover:text-white duration-300"
              rel="noopenor">
            Kaushal Purohit
          </a>
        </p>
        <p class="text-md text-gray-400 mt-1">
          2021 - Present
        </p>
      </div>
  </div>
</template>

<script>

export default {
  name: 'foot',
  components: {
  }
}
</script>

<style>
</style>
