<template>
  <section id="nav" class="h-10 mx-4 md:mx-32 my-2 md:text-xl text-md font-semibold animate-slide-down">
    <div class="logo float-left pt-2 text-2xl">
      <a class="hover:text-white duration-300" href="/">KP</a>
    </div>
    <div class="invisible md:visible float-right pt-2">
      <a  href="#about" class="duration-200 border-b-2 border-transparent hover:border-under">
        About
      </a>
      <a class="ml-8 duration-200 border-b-2 border-transparent hover:border-under" href="#projects">
        Projects
      </a>
      <a  class="ml-8 duration-200 border-b-2 border-transparent hover:border-under" href="#contact">
        Contact
      </a>
    </div>
  </section>
</template>

<script>

export default {
  name: 'navbar',
  components: {
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
.logo {
  font-family: 'Major Mono Display', monospace;
}
</style>
