<template>
  <navbar></navbar>
  <router-view />
  <hr class="animate-footer">
  <foot></foot>
</template>

<script>
import navbar from './components/navbar.vue'
import foot from './components/foot.vue'

export default {
  name: 'App',
  components: {
    navbar,
    foot
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500&display=swap');

body, html {
  padding: 0;
  margin: 0;
  background-color: #070713;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #fd2155 transparent !important;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
   background: #fd2155 !important;
}
hr{
  border: 1px solid #fd2155;
  width: 85%;
  margin: auto !important;
}
#app {
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: 500;
  color: #2984f3;
  -webkit-tap-highlight-color: transparent;
}
.slide {
  animation: slide 800ms ease-in forwards;
}
@media screen and (max-width: 768px) {
  .slide {
    animation: slidemob 800ms ease-in forwards;
  }
  @keyframes slidemob {
  0% {
    opacity: 0;
    transform: translateY(60%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
